<template>
  <b-link to="/">
    <b-img
      fluid
      :src="require('@/assets/images/logo/logo.png')"
      alt="Logotipo Indetec"
      width="150px"
    />
  </b-link>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
  },
}
</script>
